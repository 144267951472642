import '../styles/App.scss';
import Tedious from './Tedious';


function App() {
  return (
      <>
        <Tedious />
      </>
  );
}

export default App;

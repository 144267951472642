import '../styles/App.scss';
// import Tedious from './Tedious';


export default function Contact () {
    return (
      <>
        <h1 className="modalTitle">CONTACT</h1>
        <article className="modalText">
          For questions and queries about music or shows:
          <div>its.tedious.music@gmail.com</div>
        </article>
      </>
  );
}